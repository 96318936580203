@use "../../styles/variables";

.resume {
  background-color: variables.$white;
  font-family: Palatino, "Times New Roman", Times, serif;
  font-size: 1.2rem;
  position: relative;

  &::before {
    background: variables.$black;
    background: linear-gradient(90deg, variables.$black 0%, variables.$black-rose 100%);
    content: "";
    height: 100%;
    position: absolute;
    top: 0;
    width: 130px;
    z-index: 1;
  }

  .resume-inner {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-left: 130px;
    padding: 2rem;
    position: relative;
    z-index: 2;
  }
}

@include variables.mobile {
  .resume {
    &::before {
      display: none;
    }

    .resume-inner {
      margin-left: 0;
    }
  }
}
