@use "./styles/variables";

@import-normalize;

body {
  background-color: variables.$black-rose;
}

#root {
  margin: 0 auto;
  max-width: 1200px;
}

main > div {
  margin: 3rem 1.5rem;
}

@include variables.mobile {
  main > div {
    margin: 1.5rem;
  }
}
