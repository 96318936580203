@use "../../styles/variables";

header {
  background: url(./nav-bg.jpg) repeat-x;
  border-bottom: 4px solid variables.$black;
  display: flex;
  min-height: 130px;
  flex-flow: row wrap;

  h1 {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    margin: 0;
  }
}

@include variables.not-desktop {
  header {
    border-bottom: none;

    h1 {
      border-bottom: 4px solid variables.$black;
      flex-flow: column nowrap;
      height: 130px;
    }
  }
}
