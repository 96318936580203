@use "../../../../styles/variables";

.resume-section {
  position: relative;

  .section-header {
    background-color: variables.$white;
    color: variables.$black-rose;
    font-size: 1.5rem;
    left: -5rem;
    margin: 0;
    padding: 1rem;
    position: relative;
    text-transform: uppercase;
  }

  .section-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
}

@include variables.mobile {
  .resume-section {
    .section-header {
      border-bottom: 2px solid variables.$black-rose;
      left: 0;
      margin-bottom: 1rem;
      padding: 0;
    }
  }
}
