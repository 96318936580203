@use "../../styles/variables";

.about {
  align-items: center;
  display: flex;
  gap: 3rem;

  p {
    color: variables.$white;
    font-size: 1.9rem;
    font-weight: 600;
    line-height: 1.2;
    margin: 0;
    min-width: 300px;
  }
}

@include variables.mobile {
  .about {
    flex-direction: column;

    img {
      max-width: 95%;
    }
  }
}
